import React, { useEffect } from "react";
import { getLayout } from "components/layouts/PageLayout";
import Link from "next/link";
import {
  ChevronRightIcon,
  GlobeIcon,
  LightningBoltIcon,
  SparklesIcon,
} from "components/icons";
import { FeatureCard, TextLink } from "components/page";
import { useRouter } from "next/router";
import { showNotificationDialog } from "../components/dialog";
import { ensureString } from "../lib/utils";

function HomePage(): JSX.Element {
  const { query, isReady, push } = useRouter();
  useEffect(() => {
    if (isReady && query?.deletedList) {
      showNotificationDialog({
        confirmContent: <span className="mx-5">Oke</span>,
        title: (
          <>
            Lijst &quot;{decodeURIComponent(ensureString(query.deletedList))}
            &quot; is verwijderd.
          </>
        ),
        onConfirm: () => {
          push("/");
        },
      });
    }
  }, [query.deletedList, isReady, push]);
  return (
    <>
      {/* Homepage Hero */}
      <div>
        <h1 className="pb-5 my-8 font-header font-bold text-5xl text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-orange-600 sm:text-6xl sm:mb-6 md:text-7xl md:my-14">
          Nooit meer in WhatsApp gesprekken terugzoeken.
        </h1>
        <p className="text-lg sm:text-2xl">
          Houd met de lijst van BenIkBij.app gemakkelijk overzicht over wie er
          bij zijn.
        </p>
        <div className="my-5 text-center sm:my-10 md:my-14">
          <Link href="/new" passHref>
            <a className="p-3 w-full shadow font-header font-bold text-lg text-white tracking-wide rounded-lg bg-yellow-500 hover:bg-orange-600 transition-colors duration-200 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-orange-500 sm:w-auto sm:text-xl sm:px-8 sm:rounded-xl md:text-2xl md:px-10 md:py-4 md:focus:ring-4">
              Maak een lijst{" "}
              <ChevronRightIcon className="h-6 inline-block sm:h-7" />
            </a>
          </Link>
        </div>
      </div>
      <hr className="border-b" />

      <FeatureCard icon={<LightningBoltIcon />} title={"Snel"}>
        <p>Aanwezigheid bijhouden in 3 stappen:</p>
        <ol className="pl-6 list-outside list-decimal">
          <li>
            <TextLink href="/new">Maak een nieuwe lijst</TextLink>
          </li>
          <li>Deel de lijst via WhatsApp, email of een ander medium</li>
          <li>Check de aanmeldingen als je ze nodig hebt!</li>
        </ol>
      </FeatureCard>

      <FeatureCard icon={<SparklesIcon />} title={"Gemakkelijk"}>
        <p>Aameldingen bijhouden was nog nooit zo makkelijk:</p>
        <ul className="pl-6 list-outside list-disc">
          <li>Geen account nodig</li>
          <li>Lijsten aanpassen in een paar klikken.</li>
        </ul>
      </FeatureCard>

      <FeatureCard icon={<GlobeIcon />} title={"Universeel"}>
        <p>
          De lijsten kan je delen met een simpele link. Het maakt dus niet uit
          of je het liefst email, WhatsApp of iets anders gebruikt.
        </p>
      </FeatureCard>
    </>
  );
}

// Home.getLayout = (page: JSX.Element) => getLayout(page, 'Home');
HomePage.getLayout = getLayout;

export default HomePage;
