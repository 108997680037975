import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import styles from "styles/dialog.module.css";
import React, { cloneElement, ReactElement, ReactNode } from "react";
import { ExclamationIcon, InformationIcon } from "./icons";
import { Button } from "./form";

type ShowConfirmDialogProps = {
  confirmText: ReactElement;
  cancelText: ReactElement;
  onConfirm: () => void;
  title: ReactNode;
  content?: ReactNode;
};
export function showConfirmDialog({
  title,
  confirmText,
  cancelText,
  onConfirm,
  content,
}: ShowConfirmDialogProps): void {
  confirmAlert({
    overlayClassName: styles.dialogOverlay,
    customUI: function ConfirmDialogUI({ onClose }) {
      const confirmCallback = () => {
        onConfirm();
        onClose();
      };
      return (
        <BaseDialog icon={<ExclamationIcon />} title={title} content={content}>
          <div className="flex flex-row-reverse justify-between mt-4 md:mt-8">
            <Button
              primary={true}
              className="w-2/5 sm:w-1/3"
              onClick={confirmCallback}
            >
              {confirmText}
            </Button>
            <Button className="w-2/5 sm:w-1/3" onClick={onClose}>
              {cancelText}
            </Button>
          </div>
        </BaseDialog>
      );
    },
  });
}

type ShowNotificationDialogProps = {
  title: ReactNode;
  content?: ReactNode;
  confirmContent: ReactNode;
  onConfirm: () => void;
};
export function showNotificationDialog({
  title,
  content,
  confirmContent,
  onConfirm,
}: ShowNotificationDialogProps): void {
  confirmAlert({
    overlayClassName: styles.dialogOverlay,
    customUI: function NotificationDialogUI({ onClose }) {
      const confirmCallback = () => {
        onConfirm();
        onClose();
      };
      return (
        <BaseDialog icon={<InformationIcon />} title={title} content={content}>
          <div className="text-center mt-4 md:mt-8">
            <Button primary={true} onClick={confirmCallback}>
              {confirmContent}
            </Button>
          </div>
        </BaseDialog>
      );
    },
  });
}

type BaseDialogProps = {
  icon: ReactElement;
  children: ReactNode;
  title: ReactNode;
  content?: ReactNode;
};
function BaseDialog({ icon, title, content, children }: BaseDialogProps) {
  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialogLabel"
      aria-describedby={content ? "dialogDesc" : undefined}
      className="w-full p-4 bg-white rounded-none sm:flex sm:items-center sm:space-x-4 md:rounded-2xl md:max-w-2xl md:space-x-8"
    >
      {cloneElement(icon, {
        className:
          "hidden text-amber-500 w-16 h-16 flex-shrink-0 sm:block md:w-20 md:h-20",
      })}
      <div>
        <div>
          <h3 id="dialogLabel" className="text-2xl font-header font-bold my-2">
            {title}
          </h3>
          {content && <p id="dialogDesc">{content}</p>}
        </div>
        {children}
      </div>
    </div>
  );
}
